import React from "react";
import Link from "@mui/joy/Link";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ListItemContent from "@mui/joy/ListItemContent";
import { Coins, Table, BookMarked, Landmark } from "lucide-react";
import { Avatar, List, ListDivider, ListSubheader, Typography } from "@mui/joy";
import { RainbowConnectButton } from './RainbowConnectButton/RainbowConnectButton';

export default function Navigation() {
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <List size="sm" sx={{ '--ListItem-radius': '8px', '--List-gap': '4px' }}>
        <ListItem nested>
          <ListSubheader sx={{ letterSpacing: '2px', fontWeight: '800' }}>
            <Typography level="body-xs">Sepolia Network(Test)</Typography>
          </ListSubheader>
          <List
            aria-labelledby="nav-list-browse"
            sx={{
              '& .JoyListItemButton-root': { p: '8px' },
            }}
          >
            <ListItem>
              <ListItemButton component={Link} href="/" underline="none">
                <ListItemDecorator>
                  <Coins size={20} />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body-lg">Bridge</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton component={Link} href="/transactions" underline="none">
                <ListItemDecorator>
                  <Table size={20} />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body-lg">Transactions</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            <ListItem>
              <ListItemButton
                component={Link}
                href="https://devs-chain.zentachain.io"
                underline="none"
              >
                <ListItemDecorator>
                  <BookMarked size={20} />
                </ListItemDecorator>
                <ListItemContent>
                  <Typography level="body-lg">Documentation</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>
            {/* <RainbowConnectButton /> */}
          </List>
        </ListItem>
      </List>
      {/* <div className="mb-40 flex h-full w-full flex-col items-center justify-center p-4">
        <RainbowConnectButton />
      </div> */}

      {/* <div className="mb-24 mt-auto list-none p-0">
        <li>
          <a
            className="focus:shadow-outline flex h-12 w-full items-center rounded-lg border-none bg-indigo-500 p-2 px-6 text-white no-underline transition-colors duration-150 hover:bg-indigo-600"
            href="https://staking.zentachain.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Landmark size={20} className="mr-2 text-white" />
            <span className="text-lg">Stake CHAINs Token</span>
          </a>
        </li>
      </div>
      <ListDivider orientation="horizontal" /> */}
    </div>
  );
}
