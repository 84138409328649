import { Chain } from 'wagmi/chains';

type ChainMetadata = {
  summary: {
    location: string;
    svg: string;
  };
  svg: string;
  description: string;
  iconUrl?: string;
};

export default [
  {
    id: 1,
    name: 'Ethereum',
    network: 'homestead',
    summary: {
      location: 'Ethereum',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: {
      default: {
        http: ['https://ethereum.publicnode.com'],
      },
      public: {
        http: ['https://ethereum.publicnode.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://etherscan.io',
      },
    },
    contracts: {
      ensRegistry: {
        address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
      },
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 14353601,
      },
    },
  },
  {
    id: 1450,
    name: 'Chain Network',
    network: 'chain',
    summary: {
      location: 'Chain',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/zentachain.png',
    description: 'Mainnet',
    iconUrl: '/icons/zentachain.png',
    nativeCurrency: {
      decimals: 18,
      name: 'Ether',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: ['https://mainnet.zentachain.io'],
      },
      public: {
        http: ['https://mainnet.zentachain.io'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Chainscan',
        url: 'https://chainscan.io',
      },
    },
    testnet: false,
  },
  {
    id: 11155111,
    network: 'sepolia',
    name: 'Sepolia',
    summary: {
      location: 'Sepolia',
      svg: '/icons/eth.svg',
    },
    svg: '/icons/eth.svg',
    description: 'Ethereum',
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'SEP',
      decimals: 18,
    },
    rpcUrls: {
      alchemy: {
        http: ['https://eth-sepolia.g.alchemy.com/v2'],
        webSocket: ['wss://eth-sepolia.g.alchemy.com/v2'],
      },
      infura: {
        http: ['https://sepolia.infura.io/v3'],
        webSocket: ['wss://sepolia.infura.io/ws/v3'],
      },
      default: {
        http: ['https://ethereum-sepolia.publicnode.com'],
      },
      public: {
        http: ['https://ethereum-sepolia.publicnode.com'],
      },
    },
    blockExplorers: {
      etherscan: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io',
      },
      default: {
        name: 'Etherscan',
        url: 'https://sepolia.etherscan.io',
      },
    },
    contracts: {
      multicall3: {
        address: '0xca11bde05977b3631167028862be2a173976ca11',
        blockCreated: 6507670,
      },
    },
    testnet: true,
  },
  {
    id: 1432,
    name: 'Chain Sepolia',
    network: 'chain-sepolia',
    summary: {
      location: 'Chain Sepolia',
      svg: '/icons/eth-summary.svg',
    },
    svg: '/icons/zentachain.png',
    description: 'Testnet',
    iconUrl: '/icons/zentachain.png',
    nativeCurrency: {
      decimals: 18,
      name: 'Chain Sepolia Ether',
      symbol: 'ETH',
    },
    rpcUrls: {
      default: {
        http: ['https://rpc-sepolia.zentachain.io'],
      },
      public: {
        http: ['https://rpc-sepolia.zentachain.io'],
      },
    },
    blockExplorers: {
      default: {
        name: 'Blockscout',
        url: 'https://explorer-sepolia.zentachain.io/',
      },
    },
    testnet: true,
  },
] as const satisfies Readonly<(Chain & ChainMetadata)[]>;
