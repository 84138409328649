import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Popover } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import chainList from 'apps/bridge/chains';
import { DisconnectWalletButton } from 'apps/bridge/src/components/DisconnectWalletButton/DisconnectWalletButton';
import { CustomChain } from 'apps/bridge/src/types/Asset';
import { useChainEnv } from 'apps/bridge/src/utils/hooks/useChainEnv';
import { useIsPermittedToBridge } from 'apps/bridge/src/utils/hooks/useIsPermittedToBridge';
import getConfig from 'next/config';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import { Button, Divider, Sheet, Typography } from '@mui/joy';
import { CopyIcon } from 'lucide-react';

const { publicRuntimeConfig } = getConfig();

type WalletModalProps = {
  isOpen: boolean;
  address: string;
  displayAddress: string;
  Close: typeof Popover.Button;
};

export function WalletModal({ isOpen, displayAddress, address, Close }: WalletModalProps) {
  return (
    <div
      className={`relative z-10 ${isOpen ? 'block' : 'hidden'} `}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* <Close>
        <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
      </Close> */}
      <div className="fixed right-[0px] top-[62px] z-10 box-border w-[260px] overflow-y-auto bg-black sm:bg-transparent">
        <div className="flex h-full justify-center text-center sm:min-h-full sm:items-center sm:p-0">
          <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm', width: 256 }}>
            <h5 className="mt-8 flex w-full justify-center font-mono text-base">
              {displayAddress}
              <CopyToClipboard text={address}>
                <CopyIcon size={16} className="ml-2 mt-1 cursor-pointer" />
              </CopyToClipboard>
            </h5>
            <div className="w-full items-center justify-center p-4 pb-8 pt-4 text-center">
              <div className="inline-block">
                <DisconnectWalletButton />
              </div>
            </div>
          </Sheet>
        </div>
      </div>
    </div>
  );
}

const ALL_SUPPORTED_CHAIN_IDS = [1, 11155111, 1450, 1432];
const TESTNET_SUPPORTED_CHAIN_IDS = [11155111, 1432];
const supportedChains = (
  publicRuntimeConfig.mainnetGALaunchFlag === 'true'
    ? ALL_SUPPORTED_CHAIN_IDS
    : TESTNET_SUPPORTED_CHAIN_IDS
).map((chainId) => chainList.find((chain) => chain.id === chainId)) as CustomChain[];

type NetworkSwitcherOptionsProps = {
  selectedChainId: number;
};

function NetworkSwitcherOptions({ selectedChainId }: NetworkSwitcherOptionsProps) {
  const { push, pathname } = useRouter();
  const { switchNetwork } = useSwitchNetwork();
  const chainEnv = useChainEnv();
  const isMainnet = chainEnv === 'mainnet';

  return (
    <>
      {supportedChains.map((chain) => {
        function handleSwitchNetwork() {
          // We are on the mainnet bridge. If trying to switch to a testnet, redirect to the corresponding bridge.
          // Otherwise, switch networks.
          if (isMainnet) {
            // Directly switch networks for mainnet without checking for the removed testnet ID
            switchNetwork?.(chain.id);
            return;
          }

          // We are on one of the testnet bridges. If trying to switch to mainnet, redirect to the mainnet bridge.
          if (chain.id === 1 || chain.id === 1450) {
            // Assuming 1450 is for mainnet
            void push(`${publicRuntimeConfig.mainnetBridgeURL}${pathname}`);
            return;
          }

          // Normal switch network functionality without the removed testnet ID
          switchNetwork?.(chain.id);
        }

        return (
          <div
            className={`mt-4 flex h-14 w-full flex-row items-center justify-between px-4 ${
              selectedChainId === chain.id ? 'rounded-md bg-indigo-400' : ''
            }`}
            key={chain.id}
          >
            <button
              type="button"
              onClick={handleSwitchNetwork}
              className={`flex flex-row space-x-2 ${
                selectedChainId === chain.id ? 'text-white' : ''
              }`}
            >
              <Image src={chain.svg} width={24} height={24} alt={chain.name} />
              <p className="font-sans">{chain.summary.location}</p>
            </button>
            {selectedChainId === chain.id && <CheckIcon width={24} height={24} color="#fff" />}
          </div>
        );
      })}
    </>
  );
}

// We show this button if the user is on the wrong network for a given bridge. Eg user is
// on Goerli but on the mainnet bridge. Some confusing situations can arise if we are in
// this state, and this is the easiest way to reliably deal with them.
function SwitchNetworkButton() {
  const { pathname } = useRouter();
  const { switchNetwork } = useSwitchNetwork();

  const handleSwitchNetwork = useCallback(() => {
    switchNetwork?.(
      parseInt(
        pathname === '/withdraw' ? publicRuntimeConfig.l2ChainID : publicRuntimeConfig.l1ChainID,
      ),
    );
  }, [pathname, switchNetwork]);

  return (
    <Typography
      fontSize={14}
      fontWeight="bold"
      sx={{
        mr: 1,
        borderRadius: 'sm',
        textAlign: 'center',
        border: 1,
        borderColor: 'primary',
        backgroundColor: 'primary',
        padding: '8px 46px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={handleSwitchNetwork}
    >
      Switch Network
    </Typography>
  );
}

export function RainbowConnectButton() {
  const { push, pathname } = useRouter();
  const [loggedIn, setLoggedIn] = useState(false);
  const { chain: currentChain } = useNetwork();
  const chainEnv = useChainEnv();
  const isMainnet = chainEnv === 'mainnet';
  const isPermittedToBridge = useIsPermittedToBridge();

  const isOnWrongNetworkForChainEnv =
    (isMainnet && currentChain?.id !== 1 && currentChain?.id !== 1450) ||
    (!isMainnet && currentChain?.id !== 11155111 && currentChain?.id !== 1432);

  return (
    <ConnectButton.Custom>
      {({ account, chain, authenticationStatus, mounted, openConnectModal }) => {
        const handleOpenConnectModal = () => {
          localStorage.setItem('autoconnect', '1');
          openConnectModal();
          setLoggedIn(true);
        };
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === 'authenticated');

        if (pathname === '/' && connected && loggedIn && isPermittedToBridge) {
          void push('/deposit');
        }
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Button
                    onClick={handleOpenConnectModal}
                    type="button"
                    variant="outlined"
                    color="neutral"
                  >
                    Connect Wallet
                  </Button>
                );
              }

              if (pathname === '/') {
                return (
                  <div className="rounded bg-white px-4 py-3.5 text-center font-sans">
                    <Link href="/deposit">Bridge assets</Link>
                  </div>
                );
              }

              return (
                <div className="flex flex-row items-center justify-center pr-7 sm:justify-center sm:pr-0">
                  {isOnWrongNetworkForChainEnv ? (
                    <SwitchNetworkButton />
                  ) : (
                    <Popover className="relative">
                      <Typography
                        component={Popover.Button}
                        fontSize={14}
                        fontWeight="bold"
                        sx={{
                          mr: 1,
                          borderRadius: 'sm',
                          textAlign: 'center',
                          border: 1,
                          borderColor: 'primary',
                          backgroundColor: 'primary',
                          padding: '8px 46px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {chain.name}
                      </Typography>
                      <Popover.Panel className="absolute z-50">
                        <Sheet
                          variant="outlined"
                          sx={{ p: 2, borderRadius: 'sm', width: 256, mt: '11px' }}
                        >
                          <NetworkSwitcherOptions selectedChainId={chain.id} />
                        </Sheet>
                      </Popover.Panel>
                    </Popover>
                  )}
                  <Popover className="relative">
                    <Popover.Button className=" ">
                      <Typography
                        fontSize={14}
                        fontWeight="bold"
                        sx={{
                          borderRadius: 'sm',
                          textAlign: 'center',
                          border: 1,
                          borderColor: 'primary',
                          backgroundColor: 'primary',
                          padding: '8px 36px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        component={Popover.Button}
                      >
                        {account.displayName}
                      </Typography>
                    </Popover.Button>

                    <Popover.Panel>
                      <WalletModal
                        address={account.address}
                        displayAddress={account.displayName}
                        Close={Popover.Button}
                        isOpen
                      />
                    </Popover.Panel>
                  </Popover>
                  <div className="block sm:hidden">
                    <DisconnectWalletButton />
                  </div>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
