import Button from '@mui/joy/Button';
import * as React from 'react';

export type BridgeButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
};

export function BridgeButton({ onClick, disabled, children }: BridgeButtonProps) {
  return (
    <div className="flex flex-col md:flex-row md:justify-end">
      <Button
        type="button"
        onClick={onClick}
        disabled={disabled}
        variant="outlined"
        color="neutral"
        className="ml-4 mr-1 md:ml-0 md:mr-0"
      >
        {children}
      </Button>
    </div>
  );
}
