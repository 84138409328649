/* eslint-disable react/jsx-no-useless-fragment */
"use client";
import React, { useEffect, useState } from "react";
import { useColorScheme } from "@mui/joy/styles";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import IconButton from "@mui/joy/IconButton";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";
import Tooltip from "@mui/joy/Tooltip";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import ListDivider from "@mui/joy/ListDivider";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import Snackbar from "@mui/joy/Snackbar";
import { ConnectWalletButton } from "@/components/ConnectWalletButton/ConnectWalletButton";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import ArticleIcon from "@mui/icons-material/Article";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { CoinsIcon } from "lucide-react";

import Navigation from "./Navigation";
import Image from "next/image";
import Logo from "../../public/images/logo/zentachain.png";

// Connect to the wallet
import { RainbowConnectButton } from "./RainbowConnectButton/RainbowConnectButton";

function ColorSchemeToggle() {
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);
  React.useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />;
  }
  return (
    <Tooltip title="Theme" variant="outlined">
      <IconButton
        id="toggle-mode"
        size="sm"
        variant="plain"
        color="neutral"
        sx={{ alignSelf: "center" }}
        onClick={() => {
          if (mode === "light") {
            setMode("dark");
          } else {
            setMode("light");
          }
        }}
      >
        {mode === "light" ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
      </IconButton>
    </Tooltip>
  );
}

function Header() {
  const [isOnline, setIsOnline] = useState(
    typeof window !== "undefined" ? navigator.onLine : false
  );
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    const updateOnlineStatus = () => {
      if (typeof window !== "undefined") {
        setIsOnline(navigator.onLine);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("online", updateOnlineStatus);
      window.addEventListener("offline", updateOnlineStatus);
      updateOnlineStatus();
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("online", updateOnlineStatus);
        window.removeEventListener("offline", updateOnlineStatus);
      }
    };
  }, []);

  const handleStatusClick = () => {
    const statusMessage = isOnline
      ? "Your connection is stable and online!"
      : "Your connection is unstable and offline!";
    setSnackbarMessage(statusMessage);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Image src={Logo} alt="Logo CHAIN" width={32} height={32} />
          <Typography
            level="h2"
            component="h1"
            sx={{ display: { xs: "none", sm: "initial" } }}
          >
            CHAIN
          </Typography>
        </Stack>
        <Box sx={{ display: { xs: "inline-flex", sm: "none" } }}>
          <IconButton
            variant="plain"
            color="neutral"
            onClick={() => setOpen(true)}
          >
            <MenuRoundedIcon />
          </IconButton>
          <Drawer
            sx={{ display: { xs: "inline-flex", sm: "none" } }}
            open={open}
            onClose={() => setOpen(false)}
          >
            <ModalClose />
            <DialogTitle>CHAIN</DialogTitle>
            <Box sx={{ px: 1 }}>
              <Navigation />
            </Box>
          </Drawer>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
            alignItems: "center",
          }}
        >
          <Dropdown>
            <MenuButton variant="outlined" color="primary" size="sm">
              Community
            </MenuButton>
            <Menu
              placement="bottom-start"
              size="sm"
              sx={{
                zIndex: "99999",
                p: 1,
                gap: 1,
                "--ListItem-radius": "var(--joy-radius-sm)",
              }}
            >
              <MenuItem component="a" href="https://zentachain.io/">
                Homepage
              </MenuItem>
              <MenuItem component="a" href="https://devs-chain.zentachain.io">
                Documentation
              </MenuItem>
              <MenuItem
                component="a"
                href="https://discord.com/invite/TR5bv3e8Ny"
              >
                Discord
              </MenuItem>
              <MenuItem
                component="a"
                href="https://t.me/ZentachainOfficialChat"
              >
                Telegram
              </MenuItem>
              <MenuItem component="a" href="https://twitter.com/zentachain">
                Twitter (X)
              </MenuItem>
            </Menu>
          </Dropdown>
          <Tooltip title="Zentachain Blog" variant="outlined">
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              component="a"
              href="https://zentachain.blog/"
              sx={{ alignSelf: "center" }}
            >
              <ArticleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zentachain" variant="outlined">
            <IconButton
              size="sm"
              variant="plain"
              color="neutral"
              component="a"
              href="https://staking.zentachain.io/"
              sx={{ alignSelf: "center" }}
            >
              <CoinsIcon />
            </IconButton>
          </Tooltip>
          <ColorSchemeToggle />
          <IconButton
            size="md"
            variant="outlined"
            color={isOnline ? "danger" : "success"}
            onClick={handleStatusClick}
            sx={{
              display: { sm: "inline-flex" },
              borderRadius: "50%",
            }}
          >
            <LanguageRoundedIcon />
          </IconButton>
          {/* <ConnectButton
            label="Connect"
            accountStatus="avatar"
            chainStatus="icon"
            showBalance={{
              smallScreen: false,
              largeScreen: true,
            }}
          />
          <ConnectWalletButton />
          <Avatar
            src="https://avatars.githubusercontent.com/u/6374891?v=4"
            alt="User"
            size="sm"
          /> */}
          <ListDivider orientation="vertical" />

          <RainbowConnectButton />
          
          <Snackbar
            open={snackbarOpen}
            onClose={(event, reason) => {
              if (reason === "clickaway") {
                return;
              }
              handleSnackbarClose();
            }}
            autoHideDuration={6000}
            variant={isOnline ? "outlined" : "soft"}
            color={isOnline ? "success" : "danger"}
          >
            {snackbarMessage}
          </Snackbar>
        </Box>
      </Box>
    </>
  );
}

export default Header;
