/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import './global.css';
import '@rainbow-me/rainbowkit/styles.css';
import React, { Fragment } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import localFont from '@next/font/local';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { OFACProvider } from 'apps/bridge/src/contexts/OFACContext';
import { TOSProvider } from 'apps/bridge/src/contexts/TOSContext';
import { connectWallet } from 'apps/bridge/src/wallet/connect';
import App, { AppContext, AppProps } from 'next/app';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { WagmiConfig } from 'wagmi';
import { CssVarsProvider } from '@mui/joy/styles';
import Header from 'apps/bridge/src/components/Header';
import Layouts from '../../bridge/src/components/Layouts';
import Navigation from '../../bridge/src/components/Navigation';
import FAQ from '../../bridge/src/components/Faq';

import Script from "next/script";
import { GoogleAnalytics } from '@next/third-parties/google';

const { publicRuntimeConfig } = getConfig();


export async function getInitialProps(context: AppContext) {
  const appProps = await App.getInitialProps(context);

  return appProps;
}

const queryClient = new QueryClient();

const allowedPaths = new Set(['/withdraw', '/deposit', '/transactions']);

function Root({ Component, pageProps }: AppProps) {
  const { pathname } = useRouter();
  const { ...props } = pageProps as { props: unknown };
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { chains: providerChains, wagmiConfig } = connectWallet([
    parseInt(publicRuntimeConfig.l1ChainID),
    parseInt(publicRuntimeConfig.l2ChainID),
  ]);

  return (
    <>
      <GoogleAnalytics gaId="G-123666404-1" />
      {/* <Script async src="https://www.googletagmanager.com/gtag/js?id=UA-123666404-1" />
      <Script
        id="google-analytics-script" // Unique ID for the Google Analytics script
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-123666404-1');
        `}
      </Script>
      <Script
        id="hotjar-script" // Unique ID for the Hotjar script
        strategy="afterInteractive"
      >
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3778731,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </Script> */}
      <CssVarsProvider disableTransitionOnChange>
        <QueryClientProvider client={queryClient}>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={providerChains} modalSize="compact">
              <TOSProvider>
                <OFACProvider>
                  <Layouts.Root
                    sx={{
                      gridTemplateColumns: {
                        xs: '1fr',
                        sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
                        md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)',
                      },
                      ...(drawerOpen && {
                        height: '100vh',
                        overflow: 'hidden',
                      }),
                    }}
                  >
                    <Layouts.Header>
                      <Header />
                    </Layouts.Header>
                    <Layouts.SideNav>
                      <Navigation />
                    </Layouts.SideNav>
                    <Layouts.Main>
                      <Component {...props} />
                    </Layouts.Main>
                    <FAQ />
                  </Layouts.Root>
                </OFACProvider>
              </TOSProvider>
            </RainbowKitProvider>
          </WagmiConfig>
        </QueryClientProvider>
      </CssVarsProvider>
    </>
  );
}

Root.getInitialProps = getInitialProps;

export default Root;
