// FAQ.tsx
'use client';
import React from "react";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Divider from "@mui/joy/Divider";
import Accordion from "@mui/joy/Accordion";
import AccordionSummary from "@mui/joy/AccordionSummary";
import AccordionDetails from "@mui/joy/AccordionDetails";
import Sheet from "@mui/joy/Sheet";
import AccordionGroup from "@mui/joy/AccordionGroup";

const faqData = [
  {
    question: "What is the CHAIN bridge?",
    answer:
      "The CHAIN bridge is a decentralized application that allows users to transfer Ethereum between L1 to L2 the CHAIN network.",
  },
  {
    question: "What wallets are supported?",
    answer:
      "The CHAIN bridge supports MetaMask, WalletConnect, and Coinbase Wallet. If you are using a mobile device, you can also use Trust Wallet.",
  },
  // {
  //   question: "What tokens are supported?",
  //   answer:
  //     "The CHAIN bridge supports all tokens that are on Ethereum CHAIN network. If you do not see your token, you can add it manually. If you would like to add a token, please contact us.",
  // },
  {
    question: "How long does it take to transfer?",
    answer:
      "The CHAIN bridge is a decentralized application, meaning that the speed of the bridge depends on the network congestion of Ethereum and CHAIN network. If the networks are congested, it may take a few minutes for your transaction to be processed and done.",
  },
  {
    question: "Can I cancel my transfer?",
    answer:
      "No, if a withdrawal has already been initiated on the Withdraw section it is not possible to cancel it.",
  },
  {
    question: "How much does it cost to transfer tokens?",
    answer:
      "The cost of the bridge depends on the network congestion of Ethereum and CHAIN network. If the networks are congested, it may cost a few dollars to transfer.",
  },
  {
    question: "What is if i have a issue with the bridge?",
    answer:
      "If you have a problem or an issue with the bridge, please contact us or join our Discord server. You can find the link to our Discord server in the header section.",
  },
];

const FAQ: React.FC = () => {
  return (
    <Sheet
      sx={{
        display: { xs: "none", sm: "initial" },
        borderLeft: "1px solid",
        borderColor: "divider",
      }}
    >
      <Box
        sx={{
          p: 0.5,
          display: "flex",
          alignItems: "center",
          gap: 1,
          mb: 1,
          mt: 1,
        }}
      >
        <Typography sx={{ flex: 1, fontSize: '20px', ml: 1 }}>
          Frequently asked question
        </Typography>
      </Box>

      <AccordionGroup color="neutral" size="sm" variant="plain">
        {faqData.map((faq, index) => (
          <React.Fragment key={index}>
            <Accordion sx={{ p: 1 }}>
              <AccordionSummary>
                <Typography>{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
            {index < faqData.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </AccordionGroup>
    </Sheet>
  );
};

export default FAQ;

